body {
    margin: 0;
    font-family: 'Nunito', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.my-color {
    color: #0d6efd !important;
}

.ant-btn-primary {
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
}

.ant-collapse-content {
    background-color: #fafafa !important;
}

.ant-collapse-content-box {
    border-radius: .5rem;
}

input {
    border-radius: .5rem !important;
    height: 46px;
}

textarea {
    border-radius: .5rem !important;
}