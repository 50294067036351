.spinner {
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
}

.ant-page-header {
    padding: 16px 0 !important;
}

.ant-layout-content {
    background-color: #FFFFFF;
}

#header {
    background: #fff;
    box-shadow: 0 2px 8px #f0f1f2;
}

#header {
    position: relative;
    z-index: 10;
    max-width: 100%;
    background: #fff;
    box-shadow: 0 2px 8px #f0f1f2;
    padding: 0;
}

#header .ant-row {
    flex-flow: nowrap;
    height: 64px;
    row-gap: 0px;
}

#logo {
    float: left;
    height: 64px;
    padding-left: 20px;
    overflow: hidden;
    color: #000000d9;
    font-weight: 400;
    font-size: 16px;
    line-height: 64px;
    white-space: nowrap;
    text-decoration: none;
    font-family: 'Nunito', sans-serif;
}

#logo img {
    margin-right: 12px;
}

.anchor {
    display: block;
    position: relative;
    top: -64px;
    visibility: hidden;
}

.ant-typography {
    font-family: 'Nunito', sans-serif;
    font-weight: 400 !important;
}

.ant-btn {
    font-family: 'Nunito', sans-serif;
}

.ant-menu {
    font-family: 'Nunito', sans-serif;
}

.ant-comment-content {
    font-family: 'Nunito', sans-serif;
}

.ant-collapse-expand-icon {
    font-size: 18px;
}

.ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 0 0 .5rem .5rem !important;
}

.ant-avatar-string {
    transform: scale(1) translateX(-50%);
}

p {
    margin-top: 1.2em;
    margin-bottom: 0.5em;
    font-size: 20px;
    line-height: 1.4;
    overflow-wrap: break-word;
}

.small {
    font-size: 16px !important;
    line-height: 1.5 !important;
}

.ant-btn {
    line-height: 44px !important;
    height: 46px !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.ant-btn-round {
    border-radius: .5rem !important;
}

.ant-alert {
    border-radius: .5rem !important;
}

.my-shadow {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.ant-layout-footer {
    background: #FAFAFA;
}